<template>
  <div :class="$style.infoCard">
    <div
      :class="$style.infoItem"
      :title="item.content"
      v-for="item in info"
      :key="item.name"
    >
      <span :class="$style.name">{{ item.name }}：</span>
      <slot :info="item">
        <span> {{ item.content }} </span>
      </slot>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class InfoCard extends Vue {
  @Prop({ type: Array, default: () => [] }) info;
}
</script>
<style lang="less" module>
.infoCard {
  margin: 20px 20px 0;
  padding: 10px 20px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 var(--primary);
  display: flex;
  flex-wrap: wrap;
  .infoItem {
    padding: 5px 0;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .name {
      color: #999999;
    }
  }
}
</style>
