<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
      />
    </div>
    <div
      id="TMap"
      :style="{
        height: '500px',
        width: '100%',
      }"
      v-if="!noData"
    ></div>
    <div v-if="noData" style="height: 85%; background-color: #fff;">
      <EmptyContent />
    </div>
  </tab-container>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import Map from '@/components/gd-map/map';
import { getGpsData } from '@/services/device-manage/smart-helmet';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    TabContainer,
    EmptyContent,
  },
})
export default class Trajectory extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  beforeDestroy() {
    this.destroyMap();
  }
  mounted() {
    this.getTrack();
  }
  destroyMap() {
    if (this.map) {
      this.map.destroy();
    }
  }
  get queryParams() {
    return {
      idxDeviceId: this.deviceId,
      startTime: this.$moment()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endTime: this.$moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  // 轨迹格式[['经度', '维度']]
  lineArr = [];
  map = null;
  noData = false;
  async getTrack() {
    try {
      const data = await getGpsData(this.queryParams);
      if (data.length) {
        this.lineArr = data.map(item => [item.lng, item.lat]);
        this.map = new Map('TMap', this.lineArr[0]);
        this.loadMap();
      } else {
        this.destroyMap();
        this.noData = true;
      }
    } catch {
      this.destroyMap();
      this.noData = true;
    }
  }
  iconSize = 35;
  loadMap() {
    this.noData = false;
    this.map.initMap(() => {
      this.map.addPointWithAddress(
        require('@/assets/images/start.png'),
        this.lineArr[0],
        -this.iconSize / 2,
        -28,
      );
      this.map.addPointWithAddress(
        require('@/assets/images/end.png'),
        this.lineArr[this.lineArr.length - 1],
        -this.iconSize / 2,
        -28,
      );
      this.map.drawLine(this.lineArr);
    });
  }
  onChangeTimeRange(time) {
    this.queryParams.startTime = this.$moment(time[0]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.queryParams.endTime = this.$moment(time[1]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.getTrack();
  }
}
</script>
<style lang="less" module></style>
