<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceName')" prop="deviceName">
          <a-input
            :placeholder="$t('safety.inputDeviceName')"
            v-model="form.deviceName"
            :maxLength="32"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceCode')" prop="deviceCode">
          <a-input
            :placeholder="$t('safety.inputDeviceCode')"
            v-model="form.deviceCode"
            :disabled="!canEdit"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item label="设备密钥" prop="deviceKey">
          <a-input
            placeholder="请输入设备密钥"
            v-model="form.deviceKey"
            :disabled="!canEdit"
            :maxLength="16"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.belongProject')" prop="projectId">
          <a-select
            show-search
            allowClear
            v-model="form.projectId"
            :placeholder="$t('safety.chooseProject')"
            @change="handleProjectChange"
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              :value="item.pkId"
              v-for="item in projectList"
              :key="item.pkId"
            >
              {{ item.projectName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item :label="$t('common.remark')" prop="remark">
          <a-input
            :placeholder="$t('safety.inputRemark')"
            v-model="form.remark"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { deviceTypeEnum } from '@/enum/device';
import FormCommon from '@/views/green-construction/components/mixins/form-common';
import { addDevice } from '@/services/device-manage/device';
import { upData } from '@/services/device-manage/smart-helmet';

@Component()
export default class HelmetForm extends mixins(FormCommon) {
  form = {
    deviceName: '',
    deviceCode: '',
    projectId: undefined,
    deviceKey: '',
    remark: '',
  };
  rules = {
    deviceName: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceName'),
      },
    ],
    deviceCode: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceCode'),
      },
    ],
    deviceKey: [
      {
        required: true,
        message: '设备密钥不能为空',
      },
    ],
  };
  get postData() {
    return {
      deviceGroup: deviceTypeEnum.SH.deviceGroup,
      deviceName: this.form.deviceName,
      deviceCode: this.form.deviceCode,
      idxProjectId: this.form.projectId,
      remark: this.form.remark,
      deviceKey: this.form.deviceKey,
    };
  }
  initFormData(data) {
    this.form.deviceName = data.deviceName;
    this.form.deviceCode = data.deviceCode;
    this.form.projectId = data.idxProjectId;
    this.form.remark = data.remark;
    this.form.deviceKey = data.encryptionKey;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const type = this.operationType === 'add';
          const params = JSON.parse(JSON.stringify(this.postData));
          delete params.deviceGroup;
          if (!type) {
            params.deviceId = this.deviceId;
          }
          try {
            const str = type
              ? this.$t('common.addSuccess')
              : this.$t('common.modifySuccess');
            this.operationType === 'add'
              ? await addDevice(params, this.postData.deviceGroup)
              : await upData(params);
            this.$message.success(str);
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style module>
.row {
  margin-bottom: 20px;
}
.row:last-of-type {
  margin-bottom: 0;
}
</style>
