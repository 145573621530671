<template>
  <div :class="$style.tabContainer">
    <div :class="$style.operation">
      <slot name="operation" />
    </div>
    <slot />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class TabContainer extends Vue {}
</script>
<style lang="less" module>
.tabContainer {
  padding: 0 20px 10px;
  position: relative;
  overflow: auto;
  .operation {
    width: 100%;
    padding-bottom: 10px;
  }
}
</style>
