<template>
  <tab-container>
    <div
      id="RtMap"
      :style="{
        height: '500px',
        width: '100%',
      }"
      v-if="!noData"
    ></div>
    <div v-if="noData" style="height: 85%">
      <EmptyContent />
    </div>
  </tab-container>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import Map from '@/components/gd-map/map';
import VideoPlayer from '@/components/video-player';
import { getGpsData } from '@/services/device-manage/smart-helmet';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    TabContainer,
    VideoPlayer,
    EmptyContent,
  },
})
export default class RtPositioning extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  position = [116.397428, 39.90923];
  map = new Map('RtMap', this.position);
  mounted() {
    this.getRtLocation();
  }
  beforeDestroy() {
    if (this.map) {
      this.map.destroy();
    }
  }
  noData = false;
  async getRtLocation() {
    try {
      const params = {
        idxDeviceId: this.deviceId,
      };
      const data = await getGpsData(params);
      this.position[0] = data[0].lng;
      this.position[1] = data[0].lat;
      if (data[0].lng === 0 && data[0].lat === 0) {
        this.noData = true;
        return;
      }
      this.loadMap();
    } catch {
      this.noData = true;
    }
  }
  iconSize = 35;
  loadMap() {
    this.noData = false;
    this.map.initMap(() => {
      this.map.addPointWithAddress(
        require('@/assets/images/position.png'),
        this.position,
        -this.iconSize / 2,
        -28,
      );
    });
  }
}
</script>
<style lang="less" module>
/* 隐藏高德logo  */
:global .amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
:global .amap-copyright {
  display: none !important;
}
:global .amap-icon img {
  height: 34px;
}
:global .infoWindow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 400px;
  img {
    height: 125px;
    margin-left: 20px;
  }
}
</style>
