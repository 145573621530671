import { Component, Vue, Prop } from 'vue-property-decorator';
import { deviceAlarmList } from '@/services/device-manage/device';
import { deviceTypeList } from '@/services/device-manage/device-detail';

@Component
export default class DetailTable extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  mounted() {
    this.getTableList();
    this.getTypeList();
  }
  queryParams = {
    deviceId: '',
    startTime: '',
    endTime: '',
    manual: false,
    query: {
      current: 1,
      size: 10,
    },
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  loading = true;
  async getTableList() {
    this.loading = true;
    if (this.manual) {
      this.queryParams.manual = this.manual;
    }
    this.queryParams.deviceId = this.deviceId;
    try {
      const { records, current, size, total } = await deviceAlarmList(
        this.queryParams,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  typeList = [];
  async getTypeList() {
    try {
      this.typeList = await deviceTypeList(this.deviceGroup);
    } catch {
      return false;
    }
  }
  exchangeValue(value) {
    const idx = this.typeList.findIndex(v => v.value === value);
    return this.typeList[idx].label;
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.query.current = data.current;
    this.queryParams.query.size = data.pageSize;
    this.getTableList();
  }
  onChangeTimeRange(time) {
    this.queryParams.startTime = this.$moment(time[0]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.queryParams.endTime = this.$moment(time[1]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.pagination.current = 1;
    this.queryParams.query.current = 1;
    this.getTableList();
  }
  initData(v) {
    if (v.length === 0) {
      this.queryParams.startTime = '';
      this.queryParams.endTime = '';
      this.pagination.current = 1;
      this.queryParams.query.current = 1;
      this.getTableList();
    }
  }
}
