import request from '../request';

const serviceName = '/smart-site/device/sh';

// 成员绑定
export function memberBinding(data) {
  return request(`${serviceName}/binding`, {
    method: 'GET',
    body: data,
  });
}

// 获取成员绑定列表
export function getMemberList(data) {
  return request(`/smart-site/project-member/list`, {
    method: 'GET',
    body: data,
  });
}

// 获取成员绑定记录
export function getMemberBindingRecord(data) {
  return request(`${serviceName}/record`, {
    method: 'GET',
    body: data,
  });
}

// 获取直播地址
export function getLiveStream(id) {
  return request(`${serviceName}/liveStream?deviceId=${id}`, {
    method: 'GET',
  });
}

// 获取收费地址
export function getPayUrl(id) {
  return request(`${serviceName}/livePay?deviceId=${id}`, {
    method: 'GET',
  });
}

// 获取gps定位和轨迹
export function getGpsData(data) {
  return request(`${serviceName}/locations`, {
    method: 'GET',
    body: data,
  });
}

// 安全帽的编辑
export function upData(data) {
  return request(`${serviceName}/upData`, {
    method: 'POST',
    body: data,
  });
}
