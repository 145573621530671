<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 1200 }"
      @change="handlePagination"
    />
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import AlarmTable from '@/views/green-construction/components/mixins/alarm-table';

@Component({
  components: {
    TabContainer,
  },
})
export default class HelmetAlarm extends mixins(AlarmTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('greenConstruction.alarmTime'),
        dataIndex: 'createdTime',
        ellipsis: true,
        width: 180,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('greenConstruction.alarmType'),
        dataIndex: 'subType',
        customRender: text => (text ? this.exchangeValue(text) : ''),
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.alarmAddress'),
        dataIndex: 'alarmAddress',
        customRender: (text, record) =>
          record.extData ? record.extData.alarmAddress : '',
        ellipsis: true,
        width: 320,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.memberName'),
        dataIndex: 'name',
        customRender: (text, record) =>
          record.bindMemberVO ? record.bindMemberVO.name : '',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.phoneNumber'),
        dataIndex: 'phone',
        customRender: (text, record) =>
          record.bindMemberVO ? record.bindMemberVO.mobile : '',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.department'),
        dataIndex: 'department',
        customRender: (text, record) =>
          record.bindMemberVO ? record.bindMemberVO.department : '',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.role'),
        dataIndex: 'role',
        customRender: (text, record) =>
          record.bindMemberVO ? record.bindMemberVO.role : '',
        ellipsis: true,
      },
    ];
  }
  deviceGroup = deviceTypeEnum.SH.deviceGroup;
}
</script>

<style lang="less" module></style>
