import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DetailTable extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  mounted() {
    this.getTableList();
  }
  queryParams = {
    pkId: '',
    startTime: '',
    endTime: '',
    current: 1,
    size: 10,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  loading = true;
  async getTableList() {
    this.loading = true;
    this.queryParams.pkId = this.deviceId;
    try {
      const { records, total } = await this.getRecordList(
        this.queryParams,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.current = data.current;
    this.queryParams.size = data.pageSize;
    this.getTableList();
  }
  onChangeTimeRange(time) {
    this.queryParams.startTime = this.$moment(time[0]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.queryParams.endTime = this.$moment(time[1]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.loadTableData();
  }
  initData(v) {
    if (v.length === 0) {
      this.queryParams.startTime = '';
      this.queryParams.endTime = '';
      this.loadTableData();
    }
  }
  loadTableData() {
    this.pagination.current = 1;
    this.queryParams.current = 1;
    this.getTableList();
  }
}
