<template>
  <div :class="$style.content">
    <div>
      <table-search
        @handle-search-submit="handleSearchSubmit($event)"
        @handle-add-device="showFormModel({}, 'add')"
        :showStatusNum="false"
      />
      <a-spin tip="视频加载中..." :spinning="spinning">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          :row-key="record => record.deviceId"
          :loading="loading"
          :transformCellText="({ text }) => text || '-'"
          @change="handlePagination"
        >
          <template slot="member" slot-scope="text, record">
            <span>{{
              record.bindMemberVO ? record.bindMemberVO.name : ''
            }}</span>
            <a-icon
              v-if="record.projectName"
              type="edit"
              @click="bindMember(record)"
            />
          </template>
          <template slot="online" slot-scope="record">
            <span v-if="record" class="online-color">{{
              $t('greenConstruction.online')
            }}</span>
            <span v-else class="offline-color">{{
              $t('greenConstruction.offline')
            }}</span>
          </template>
          <template slot="action" slot-scope="record">
            <button-groups
              @handle-edit="showFormModel(record, 'edit')"
              @handle-play="showVideo(record)"
              @handle-detail="showDetail(record)"
              @handle-delete="deleteTips(record)"
              :num="record.alarmCount"
              :showVideo="true"
            />
          </template>
        </a-table>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal, createModal } from '@triascloud/x-components';
import FormModel from './helmet-form.vue';
import BindMember from './bind-member.vue';
import DeviceDetail from './detail/index.vue';
import VideoPlayer from '@/components/video-player';
import ButtonGroups from '@/views/green-construction/components/button-groups.vue';
import tableSearch from '@/views/green-construction/components/table-search.vue';
import { deviceTypeEnum } from '@/enum/device';
import TableCommon from '@/views/green-construction/components/mixins/table-common';
import {
  getLiveStream,
  getPayUrl,
} from '@/services/device-manage/smart-helmet';

@Component({
  components: {
    LayoutContent,
    DeviceDetail,
    FormModel,
    VideoPlayer,
    ButtonGroups,
    tableSearch,
    BindMember,
  },
})
export default class SmartHelmet extends mixins(TableCommon) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
      },
      {
        title: this.$t('peopleManagement.bindingMember'),
        dataIndex: 'member',
        // ellipsis: true,
        scopedSlots: { customRender: 'member' },
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        title: this.$t('safety.deviceStatus'),
        dataIndex: 'online',
        ellipsis: true,
        scopedSlots: { customRender: 'online' },
      },
      {
        title: this.$t('common.operation'),
        width: 280,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  deviceGroup = deviceTypeEnum.SH.deviceGroup;
  spinning = false;
  async showVideo(record) {
    this.spinning = true;
    try {
      const liveUrl = await getLiveStream(record.pkId);
      if (liveUrl) {
        createModal(
          () => (
            <div>
              <video-player
                url={liveUrl}
                size={{ width: '100%', height: '500px' }}
              />
              <h2 style="margin: 10px 0">{record.deviceName}</h2>
            </div>
          ),
          {
            title: '监控',
            width: 900,
            closable: true,
            maskClosable: false,
          },
        );
      }
    } catch (err) {
      if (
        err.code === 60007 &&
        err.message === '设备服务未使能，指视频流量套餐未开通'
      ) {
        const payUrl = await getPayUrl(record.pkId);
        window.open(payUrl, '_blank');
      }
    } finally {
      this.spinning = false;
    }
  }
  async bindMember(record) {
    try {
      const result = await createFormModal(
        () => (
          <bind-member
            deviceId={record.pkId}
            idxProjectId={record.idxProjectId}
            bindMemberId={
              record.extData && record.extData.bindMemberId
                ? record.extData.bindMemberId
                : undefined
            }
          />
        ),
        {
          width: '442px',
          title: this.$t('peopleManagement.bindingMember'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.content {
  padding: 20px;
}
:global(.DeviceDetail .ant-drawer-body) {
  padding: 0 !important;
}
:global {
  .DeviceDetail {
    .ant-drawer-header {
      .ant-drawer-close {
        left: 0;
      }
    }
    .feedBackBtn {
      position: fixed;
      top: 8px;
      right: 30px;
      z-index: 999;
      color: #ddd;
      font-size: 16px;
      cursor: pointer;
      .anticon {
        margin-right: 8px;
      }
    }
  }
}
</style>
