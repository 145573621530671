<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="oldBindingName" slot-scope="text, record">
        <span>{{ text }}</span>
        <span v-if="record?.oldBindingDeleted" :class="$style.isDelete"
          >已删除</span
        >
      </template>
      <template slot="bindingName" slot-scope="text, record">
        <span>{{ text }}</span>
        <span v-if="record?.bindingDeleted" :class="$style.isDelete"
          >已删除</span
        >
      </template>
    </a-table>
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import RecordTable from '@/views/green-construction/components/mixins/record-table';
import { getMemberBindingRecord } from '@/services/device-manage/smart-helmet';

@Component({
  components: {
    TabContainer,
  },
})
export default class HelmetRecord extends mixins(RecordTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.beforeModification'),
        dataIndex: 'oldBindingName',
        scopedSlots: { customRender: 'oldBindingName' },
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.afterModification'),
        dataIndex: 'bindingName',
        scopedSlots: { customRender: 'bindingName' },
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.operatingTime'),
        dataIndex: 'updatedTime',
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('detail.operator'),
        dataIndex: 'updatedByName',
        ellipsis: true,
      },
    ];
  }
  getRecordList(queryParams, deviceGroup) {
    return getMemberBindingRecord(queryParams, deviceGroup);
  }
  deviceGroup = deviceTypeEnum.SH.deviceGroup;
}
</script>

<style lang="less" module>
.isDelete {
  white-space: nowrap;
  padding: 2px 10px;
  border-radius: 16px;
  font-size: 12px;
  margin-left: 4px;
  color: #fff;
  background-color: red;
}
</style>
