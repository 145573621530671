import AMapLoader from '@amap/amap-jsapi-loader';
export default class Map {
  constructor(id, position) {
    this.id = id;
    this.position = position;
  }
  AMap = null;
  initMap(callback) {
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_API_KEY,
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Geocoder'], // 插件列表，'AMap.Geocoder'经纬度地址转换插件
    })
      .then(AMap => {
        this.AMap = AMap;
        this.loadMap(AMap);
        callback();
      })
      .catch(() => {
        // console.log(e);
      });
  }
  map = null;
  loadMap(AMap) {
    this.map = new AMap.Map(this.id, {
      viewMode: '2D', //  是否为3D地图模式
      zoom: 18, // 初始化地图显示的缩放级别
      center: this.position, //中心点坐标
      resizeEnable: true,
      mapStyle: 'amap://styles/dark', // 修改地图主题
    });
  }
  iconSize = 35;
  addPointWithAddress(
    icon,
    position,
    offsetX = -this.iconSize / 2,
    offsetY = -this.iconSize,
  ) {
    const marker = new this.AMap.Marker({
      icon,
      position,
      offset: new this.AMap.Pixel(offsetX, offsetY),
    });
    marker.setMap(this.map);
    const geocoder = new this.AMap.Geocoder();
    geocoder.getAddress(position, (status, result) => {
      if (status === 'complete' && result.regeocode) {
        //鼠标点击marker弹出自定义的信息窗体
        marker.on('click', () => {
          this.addressInfoWindow(position, result.regeocode.formattedAddress);
        });
      }
    });
  }
  addressInfoWindow(position, address) {
    // 信息窗体的内容
    const content = [
      `<span style="white-space: nowrap;padding: 0 8px;">${address}</span><br />`,
    ];
    // 创建 infoWindow 实例
    const infoWindow = new this.AMap.InfoWindow({
      content, //传入 dom 对象，或者 html 字符串
      offset: new this.AMap.Pixel(0, -this.iconSize / 3),
    });
    // 打开信息窗体
    infoWindow.open(this.map, position);
  }
  addPointWithPersonnelInfo({
    icon,
    position,
    content,
    offsetX = -this.iconSize / 2,
    offsetY = -this.iconSize,
    windowInfoClick,
  }) {
    const marker = new this.AMap.Marker({
      icon,
      position,
      offset: new this.AMap.Pixel(offsetX, offsetY),
    });
    marker.setMap(this.map);
    marker.on('click', () => {
      this.personnelInfoWindow(position, content);
      // 信息窗体内的点击事件写在回调函数里面
      windowInfoClick();
    });
  }
  personnelInfoWindow(position, content) {
    // 创建 infoWindow 实例
    const infoWindow = new this.AMap.InfoWindow({
      content, //传入 dom 对象，或者 html 字符串
      offset: new this.AMap.Pixel(0, -this.iconSize / 3),
    });
    // 打开信息窗体
    infoWindow.open(this.map, position);
  }
  drawLine(lineArr) {
    // 绘制轨迹
    new this.AMap.Polyline({
      map: this.map,
      path: lineArr,
      showDir: false, // 箭头方向
      strokeColor: '#0180F4', //线颜色
      strokeOpacity: 1, //线透明度
      strokeWeight: 8, //线宽
      strokeStyle: 'solid', //线样式
    });
    this.map.setFitView();
  }
  destroy() {
    this.map.destroy();
  }
}
