<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-row :gutter="24" :class="$style.row">
      <a-col :span="24">
        <a-form-model-item
          :label="$t('peopleManagement.bindingMember')"
          prop="bindMemberId"
        >
          <a-select
            show-search
            allowClear
            v-model="form.bindMemberId"
            :placeholder="$t('peopleManagement.selectMember')"
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              :value="item.pkId"
              v-for="item in memberList"
              :key="item.pkId"
            >
              {{ item.memberName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <!-- <a-row :gutter="24" :class="$style.row" v-if="form.type !== 3">
      <a-col :span="24">
        <a-form-model-item :label="$t('peopleManagement.bindingMember')" prop="member">
          <x-modal-select
            v-model="form.member"
            :view="view"
            :value="range"
            @input="updateRangeValue"
            :type="memberSelectRange"
            :multiple="false"/></a-form-model-item
      ></a-col>
    </a-row> -->
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  memberBinding,
  getMemberList,
} from '@/services/device-manage/smart-helmet';
// import ExternalContacts from './external-contacts.vue';

@Component({
  components: {
    // ExternalContacts,
  },
})
export default class HelmetForm extends Vue {
  @Prop({ type: String, required: true }) deviceId;
  @Prop({ type: String, default: undefined }) bindMemberId;
  @Prop({ type: String, default: undefined }) idxProjectId;
  mounted() {
    this.getMembers();
    this.form.bindMemberId = this.bindMemberId;
    this.form.deviceId = this.deviceId;
  }
  memberList = [];
  async getMembers() {
    try {
      this.memberList = await getMemberList({
        idxProjectId: this.idxProjectId,
      });
    } catch {
      return false;
    }
  }
  form = {
    bindMemberId: undefined,
    deviceId: '',
  };
  async getValue() {
    await this.$refs.form.validate();
    try {
      await memberBinding(this.form);
      this.$message.success('绑定成功');
      return true;
    } catch {
      return false;
    }
  }
  // form = {
  //   type: 1,
  //   member: [
  //     {
  //       text: 'test',
  //       value: '1298525781071421441',
  //       avatar: 'dir/自定义应用/689ccd4d-b9a9-4c06-92b0-e234fc43c6e6.jpg',
  //       type: 'user',
  //     },
  //   ],
  // };
  // rules = {
  //   member: [
  //     {
  //       required: true,
  //       message: '成员不能为空',
  //     },
  //   ],
  //   type: [
  //     {
  //       required: true,
  //       message: '类型不能为空',
  //     },
  //   ],
  // };
  // range = [
  //   {
  //     text: 'test',
  //     value: '1298525781071421441',
  //     avatar: 'dir/自定义应用/689ccd4d-b9a9-4c06-92b0-e234fc43c6e6.jpg',
  //     type: 'user',
  //   },
  // ];
  // view = false;
  // get memberSelectRange() {
  //   let selectRange;
  //   if (this.form.type === 1) {
  //     selectRange = ['org-user', 'role-user', 'user'];
  //   } else if (this.form.type === 2) {
  //     selectRange = [
  //       {
  //         tab: '外部联系人',
  //         Component: ExternalContacts,
  //       },
  //     ];
  //   }
  //   return selectRange;
  // }
  // typeList = [
  //   {
  //     id: 1,
  //     name: '绑定内部成员',
  //   },
  //   {
  //     id: 2,
  //     name: '绑定外部成员',
  //   },
  //   {
  //     id: 3,
  //     name: '闲置',
  //   },
  // ];
  // updateRangeValue(data) {
  //   this.range = data[0];
  // }
}
</script>

<style module>
.row {
  margin-bottom: 20px;
}
.row:last-of-type {
  margin-bottom: 0;
}
</style>
